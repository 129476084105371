import React from "react"
import Layout from "../../components/layout"
import ArcadePage from "../../components/ArcadePage"

function Arcade({ location }) {
  return (
    <Layout
      image="arcade-background.png"
      location={location}
      imgStyle={{ objectPosition: "center center" }}
    >
      <ArcadePage />
    </Layout>
  )
}

export default Arcade
