import React from "react"
import loadable from "@loadable/component"
import DorchaLink from "../DorchaLink"
import ArcadeMenu from "../ArcadeMenu"

import { HONEY_BADGER_URL } from "../../constants"
import css from "./ArcadePage.module.css"
import arcadeMusak from "../../audio/arcade-music.wav"

const AudioPlayer = loadable(() => import("../AudioPlayer"))

const games = [
  {
    name: "Honey Badger",
    url: HONEY_BADGER_URL,
    img: "hb-card.png",
    locked: false,
  },
  {
    name: "Honey Badger",
    locked: true,
  },
  {
    name: "Honey Badger",
    locked: true,
  },
]

function ArcadePage() {
  return (
    <>
      <div className={css.container}>
        <div>
          <h1 className={css.heading}>LET'S PLAY.</h1>
          <DorchaLink
            text="<- Home"
            to="/"
            className={css.homeLink}
            state={{
              isBackFromBlob: true,
            }}
          />
        </div>
        <ArcadeMenu games={games} />
      </div>
      <AudioPlayer audio={arcadeMusak} autoPlay={false} fallback={<></>} />
    </>
  )
}

export default ArcadePage
