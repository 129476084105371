import React from "react"

import css from "./ArcadeMenu.module.css"
import GameOptionCard from "../GameOptionCard"

function ArcadeMenu({ games = [] }) {
  return (
    <div className={css.container}>
      {games.map((gameInfo, i) => {
        return <GameOptionCard key={i} {...gameInfo} />
      })}
    </div>
  )
}

export default ArcadeMenu
